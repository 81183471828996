export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USER = "GET_USER";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_USER = "UPDATE_USER";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const POST_LOADING = "POST_LOADING";
export const HIRE_LOADING = "HIRE_LOADING";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const ADD_POST = "ADD_POST";
export const DELETE_POST = "DELETE_POST";
export const DELETE_HIRE = "DELETE_HIRE";
export const GET_POSITIONS = "GET_POSITIONS";
export const GET_POSITION = "GET_POSITION";
export const POSITION_LOADING = "POSITION LOADING";
export const ADD_POSITION = "ADD_POSITION";
export const DELETE_POSITION= "DELETE_POSITION";
export const GET_EXPERIENCE= "GET_EXPERIENCE";
export const GET_EDUCATION= "GET_EDUCATION";
export const GET_BUSINESS= "GET_BUSINESS";
export const GET_BUSINESSES= "GET_BUSINESSES";
export const GET_BUSINESSES_CRITERIA = "GET_BUSINESSES_CRITERIA";
export const GET_HIRE= "GET_HIRE";
export const GET_POSITIONS_CRITERIA = "GET_POSITIONS_CRITERIA";
export const CLEAR_CURRENT_BUSINESS = "CLEAR_CURRENT_BUSINESS";
export const BUSINESS_LOADING = "BUSINESS_LOADING";
export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const GET_PROFILES_CRITERIA = "GET_PROFILES_CRITERIA";
export const GET_IMAGE_URL = "GET_IMAGE_URL";
export const GET_PINNED = "GET_PINNED";
export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOG = "GET_BLOG";
export const GET_PODCASTS = "GET_PODCASTS";
export const GET_PODCAST = "GET_PODCAST";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const CONTENT_LOADING = "CONTENT_LOADING";
export const GET_ORGINIZATION_EMAIL_INFO = "GET_ORGINIZATION_EMAIL_INFO";
export const ADMIN_GET_CHAPTERS = "ADMIN_GET_CHAPTERS";
export const ADMIN_GET_CHAPTER = "ADMIN_GET_CHAPTER";
export const ADMIN_GET_USERS = "ADMIN_GET_USERS";
export const ADMIN_GET_USER = "ADMIN_GET_USER";
export const ADMIN_LOADING = "ADMIN_LOADING";
export const ADMIN_ADD_CHAPTER = "ADMIN_ADD_CHAPTER";
export const ADMIN_GET_ORGINIZATIONS = "ADMIN_GET_ORGINIZATIONS";

