import React from 'react'

export default function NotFound() {
  return (
    <div>
        <h1 className='display-4'>Page Not Found</h1>
        <p>Sorry, this page does not exist</p>
      
    </div>
  )
}
